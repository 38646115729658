import { CONTRACTS_FIELDS } from "./contract";
import { PAGE_LIMIT, directus, getPagingQuery, pureDate2ymd } from "./directus";

export const PLAN_ITEMS_FIELDS = [
  "item_no",
  "plan_no",
  "prod_no",
  "item_price",
  "item_3d_json",
  "sort",
];

/**
 * plans 목록
 * @param {String} search
 * @param {object} filter
 * @param {String[]} fields
 * @param {String[]} sort
 * @param {Boolean} isPaging
 * @param {Number} limit
 * @param {Number} page
 * @returns
 */
export const readPlanList = async ({
  search,
  filter,
  fields,
  sort,
  isPaging = true,
  limit = PAGE_LIMIT,
  page = 1,
}) => {
  const query = {
    search: search || "",
    filter: filter || {},
    fields: fields || PLAN_ITEMS_FIELDS,
    sort: sort || [],
    meta: "*",
  };

  const result = await directus
    .items("plans")
    .readByQuery(isPaging ? getPagingQuery(query, page, limit) : query);

  return result;
};

/**
 * ACTIVE PLAN : 기존에 사용했던 플랜을 재사용하여 신규 플랜을 생성
 * @param {Object} planData
 * @param {String} planData.contrNo
 * @param {String} planData.custNo
 * @param {Object} planData.formData
 * @param {String} planData.formData.plan_nm
 * @param {String} planData.formData.deliv_due_date
 * @returns Object
 */
export const activePlan = async ({
  contrNo, // 계약번호
  custNo, // 고객번호
  formData,
}) => {
  //console.log("plan.js : activePlan, contrNo =", contrNo);
  //console.log("plan.js : activePlan, custNo =", custNo);
  //console.log("plan.js : activePlan, formData =", formData);

  /** [ STEP1 ] 기존값 복사하기 *********************************************/

  // 1. 계약 및 플랜 정보 복사
  const activeContract = await directus.items("contracts").readOne(contrNo, {
    //filter: { _and: [{ order_status: { _eq: "Completed" } }] },
    fields: CONTRACTS_FIELDS,
  });
  //console.log("plan.js : activePlan, original Contract =", activeContract);
  if (!activeContract)
    throw new Error("No plan information was found to activate.");

  // 2. plan_items 키값 빼고 복사 : 읽고 목록에서 키값 빼기 복잡하니 키값빼고 다시 가져옴
  const { data: plan_items } = await directus.items("plan_items").readByQuery({
    fields: ["prod_no", "item_3d_json", "sort"],
    filter: { plan_no: { _eq: activeContract.plan_no.plan_no } },
  });
  //console.log("plan.js : activePlan, plan_items =", plan_items);
  activeContract.plan_no.plan_items = plan_items;

  // 3. relation 데이터들 중에 변경없는 부분은
  // 하위 데이터 없이(있으면 하위 테이블에 insert/update 하게됨) 기존 키값만 복사해서 입력
  activeContract.plan_no.floor_no = activeContract.plan_no.floor_no.floor_no;

  /** [ STEP2 ] 기존값을 변경할 부분 ****************************************/

  // 4. 고객정보는 새로 넣어줌 : 나중에 다른 사람들이 사용했던 플랜을 재사용할지도 모르니...
  activeContract.cust_no = custNo;
  activeContract.plan_no.cust_no = custNo;

  // 5. 폼데이터로 입력받는 정보 : 플랜명, 배송예정일
  activeContract.plan_no.plan_nm = formData.plan_nm;
  activeContract.deliv_due_date = pureDate2ymd(formData.deliv_due_date);

  // 6. 하위 데이터 수정(insert/update)이 필요한 부분은 키값을 제외하고 데이터만 가져와서 입력
  activeContract.deliv_addr_no = activeContract.deliv_addr_no
    ? {
        //addr_no: // 키값은 제외해야 신규 데이터를 생성함, floor_no의 주소 값을 가져옴
        addr_nm: activeContract.deliv_addr_no?.addr_nm,
        addr_type: activeContract.deliv_addr_no?.addr_type,
        addr_lower: null,
        addr_upper: activeContract.deliv_addr_no?.addr_upper,
        addr_post_code: activeContract.deliv_addr_no?.addr_post_code,
        addr_city_prov: activeContract.deliv_addr_no?.addr_city_prov,
      }
    : null;
  // 7. 새로 플랜 생성할 때의 상태로 만들기
  activeContract.order_status = "Planning";
  activeContract.plan_no.plan_status = "In-Progress";

  /** [ STEP3 ] 기존값을 삭제할 부분 ****************************************/

  // insert할 때 없어야할 값(키값) - 자동 생성되는 값들
  delete activeContract.contr_no;
  delete activeContract.order_id;
  delete activeContract.plan_no.plan_no;
  delete activeContract.new_item_checkers;

  // 승인(confirm) 이후에 생성되는 값들
  delete activeContract.agent_no;
  delete activeContract.item_price_sum;
  delete activeContract.tax_info_no; // 세금정보는 초기화하여 무조건 다시 입력
  delete activeContract.dpst_amt;
  delete activeContract.item_price_sum;
  delete activeContract.dc_plcy_no;
  delete activeContract.dc_amt;
  delete activeContract.final_price_tot;
  delete activeContract.dpst_amt;
  delete activeContract.baln_amt;
  delete activeContract.cnfrm_date;
  delete activeContract.cnfrm_time;
  delete activeContract.ref_time_zone;
  delete activeContract.dpst_due_date;
  delete activeContract.dpst_pay_date;
  delete activeContract.baln_pay_date;
  delete activeContract.compl_date;

  //console.log("plan.js : activePlan, insert value =", activeContract);
  const result = await directus.items("contracts").createOne(activeContract);

  //console.log("plan.js : activePlan, result =", result);
  return result;
};

/**
 * NEW PLAN
 * @param {String} custNo
 * @param {String} planNm
 * @param {integer} templateNo
 * @param {integer} floorNo
 * @returns Object
 */
export const newPlan = async ({
  cust_no,
  plan_nm,
  template,
  floor,
  contract,
}) => {
  let contractInfo;

  // 기본 정보 누락체크
  if (!cust_no || !plan_nm) {
    // 고객번호, 플랜명
    throw new Error("Required input missing.");
  }

  if (!!template?.plan_no) {
    // 분기1. 템플릿으로 생성

    // 템플릿 plan_no 누락체크
    if (!template?.plan_no) {
      // plan_no 누락
      throw new Error("plan_no missing.");
    }

    // 템플릿 정보
    const templateInfo = await directus
      .items("plans")
      .readOne(template?.plan_no, {
        fields: [
          "plan_no",
          "cust_no",
          "plan_nm",
          "plan_type",
          "orig_plan_no",
          "floor_no.floor_no",
          "floor_no.floor_addr_no",
          "plan_thumb_img",
          "plan_2d_img",
          "plan_rend_img.directus_files_id.id",
          "plan_rep_img",
          "plan_items",
          "plan_status",
          "plan_3d_json",
          "tag",
          "temp_price",
          "temp_contr_cnt",
          "temp_contr_amt",
        ],
      });

    if (!templateInfo) throw new Error("Template information not found.");
    else {
      // 템플릿 구성 상품(가구, 가전)
      const { data: plan_items } = await directus
        .items("plan_items")
        .readByQuery({
          fields: ["prod_no", "item_3d_json", "sort"],
          filter: { plan_no: { _eq: templateInfo.plan_no } },
        });

      // 템플릿 렌더링이미지
      const templatePlanRendImgs = templateInfo?.plan_rend_img || [];
      const planRendImgs = templatePlanRendImgs.map((plan_rend_img) => {
        return {
          directus_files_id: { id: plan_rend_img.directus_files_id.id },
          plans_plan_no: templateInfo.plan_no,
        };
      });

      contractInfo = {
        cust_no: cust_no,
        order_status: "Planning",
        deliv_due_date: contract.deliv_due_date,
        deliv_addr_no: contract.deliv_addr_no,
        status: "published",
        plan_no: {
          plan_nm: plan_nm,
          cust_no: cust_no,
          floor_no: templateInfo.floor_no.floor_no,
          orig_plan_no: templateInfo.plan_no,
          plan_thumb_img: templateInfo.plan_thumb_img,
          plan_rep_img: templateInfo.plan_rep_img,
          plan_2d_img: templateInfo.plan_2d_img,
          plan_rend_img: planRendImgs,
          plan_type: "plan",
          plan_status: "In-Progress",
          plan_3d_json: templateInfo.plan_3d_json,
          plan_items: plan_items,
          tag: templateInfo.tag,
          temp_price: templateInfo.temp_price,
          temp_contr_cnt: templateInfo.temp_contr_cnt,
          temp_contr_amt: templateInfo.temp_contr_amt,
          status: "published",
        },
      };
    }
  } else {
    // 분기2. 도면으로 생성

    if (!floor?.floor_no) {
      // 도면번호 누락
      throw new Error("floor_no missing.");
    }

    contractInfo = {
      cust_no: cust_no,
      order_status: "Planning",
      deliv_due_date: contract.deliv_due_date,
      deliv_addr_no: contract.deliv_addr_no,
      status: "published",
      plan_no: {
        plan_nm: plan_nm,
        cust_no: cust_no,
        plan_type: "plan",
        plan_status: "In-Progress",
        floor_no: floor.floor_no,
        status: "published",
      },
    };
  }

  if (contractInfo) {
    const result = await directus.items("contracts").createOne(contractInfo);
    return result;
  } else {
    throw new Error("Unable to generate a plan.");
  }
};
