import { PLAN_ITEMS_FIELDS } from "./plan";

export const INTERIOR_PLANS_FIELDS = [
  "plan_no",
  "cust_no",
  "plan_nm",
  "plan_type",
  "orig_plan_no",
  "floor_no.floor_no",
  "floor_no.house_type",
  "plan_thumb_img",
  "plan_2d_img",
  "plan_rep_img",
  "plan_rend_img.directus_files_id",
  "plan_status",
  "plan_3d_json",
  ...PLAN_ITEMS_FIELDS.map((field) => "plan_items.".concat(field)),
  "tag",
];
